<template>
  <v-app>
    <v-main>
      <v-app-bar color="#525252" dark>
        <v-toolbar-title>Requirements for Coming to Campus</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-app-bar>
    <covid-landing></covid-landing>
    </v-main>
    <v-footer app>
      <chatter></chatter>
      <v-col class="text-center" cols="12">
        <a class="indigo--text" href="https://umd.edu/web-accessibility" title="Information about web accessibility at UMD">Web Accessibility</a> |
        <a class="indigo--text" href="https://umd.edu/privacy-notice" title="">Privacy Notice</a>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import CovidLanding from "../../components/covid/CovidLanding";
import Chatter from "../../components/covid/Chatter";
export default {
  name: "CovidApp",
  data() {
    return {};
  },

  components: { CovidLanding, Chatter },
  methods: {},
};
</script>
<style scoped>
.v-btn {
  padding-bottom: 40px;
}

.v-content__wrap {
  position: absolute;
  right: 200px;
}
</style>
