<template>
  <div>
    <div id="chat"></div>
    <button class="chat-button" @click="toggleFormVisibility()" id="chatButton">Chat <font-awesome-icon :icon="iconSwitch()" /></button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpen: false,
      isExpanded: false
    } 
  },
  methods: {
    iconSwitch() {
      if(this.isOpen) {
        return ['fas', 'minus'];
      }
      else {
        return ['fas', 'comments'];
      }
    },
    toggleFormVisibility() {
      if(this.isOpen){
        this.closeForm();
      } else {
        this.openForm();
      }
    },

    async openForm() {
      this.isOpen = true;
      document.getElementById("chat").innerHTML = '<iframe id="chatbox" src="https://ai.chatbot.umd.edu/?botName=d_uhc_covid" style="width:327px; height: 60vh; position: fixed; right: 10px; bottom: 85px; z-index: 999; border:0;"></iframe><button id="expandButton" style="position:fixed; opacity:0; bottom:calc(60vh + 35px); right:10px; width:75px; height:50px; z-index:1000;"></button>';
      document.getElementById("chatButton").style.backgroundColor = "darkred";
      document.getElementById("expandButton").addEventListener('click', () => this.expandIframe());
    },
    closeForm() {
      this.isOpen = false;
      document.getElementById("chat").innerHTML = '';
      document.getElementById("chatButton").style.backgroundColor = "red";
    },
    expandIframe() {
      if(this.isExpanded){
      document.getElementById("chatbox").style.height = "60vh";
      document.getElementById("expandButton").style.bottom = "calc(60vh + 35px)";
      document.getElementById("chatbox").style.width = "327px";
      this.isExpanded=false;
      }
      else {
      document.getElementById("chatbox").style.height = "70vh";
      document.getElementById("expandButton").style.bottom = "calc(70vh + 35px)";
      document.getElementById("chatbox").style.width = "80vw";
      this.isExpanded=true;
      }
    }
  },
  mounted() {
    this.isOpen = false;
    this.isExpanded = false;
    document.getElementById("chat").innerHTML = '';
    document.getElementById("chatButton").style.backgroundColor = "red";
  },
}
</script>

<style scoped>

/* Button used to open the chat form - fixed at the bottom of the page */
.chat-button {
  display: inline-block;
  padding: 1px 25px;
  font-size: 15px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: red;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px #999;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 150px;
  height: 40px;
}
.chat-button:active {
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}


</style>