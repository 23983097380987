<template>
  <div id="app1">
    <v-form>
      <v-container>
        <v-row>
          <v-col cols="12" >
            <div>
              <p>The University of Maryland no longer requires students, faculty, or staff to report positive COVID-19 test results to the University Health Center. This reflects current state and federal guidelines regarding individual case reporting, which UMD has diligently followed.</p>
              <p>While reporting is no longer mandatory, the University Health Center remains a valuable resource for your health needs.</p>
              <ul>
                <li><a href="https://health.umd.edu/HEAL">The HEAL Line and Additional COVID-19 Information</a></li>
                <li><a href="https://health.umd.edu/home/uhc-operations">UHC Information and Hours of Operations</a></li>
                <li><a href="https://health.umd.edu/hpws">UHC Health Promotion and Wellness Services</a></li>
                <li><a href="/covid/chat/">HEAL Line Virtual Assistant</a></li>
              </ul>
              <br/>
              <p>Thank you for your cooperation and continued commitment to keeping our community healthy.</p>
              </div>
            <div>

            </div>

          </v-col>

        </v-row>

<!--        <v-row align="center" justify="center" >-->
<!--          <v-card >-->
<!--            <v-card-text class="healLineText">-->
<!--            <b>If you have tested positive, think you have symptoms of COVID-19, or have been exposed to COVID-19,</b> <br> please contact the <a class="healLine" href="https://health.umd.edu/HEAL" target="_blank" >  UMD HEAL line</a>.<b> If you have a medical question or concern, please contact your physician.</b>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-row>-->

        <v-row align="center" justify="center">
          <v-col
            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            class="large-help-text"
          >
            For help with this site contact
            <a href="https://itsupport.umd.edu/">IT Service Desk</a>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>
<script>
export default {
  name: "CovidLanding"
};
</script>

<style scoped>

.bigText {
  font-size: 50px;
}

.noUnderline{
  text-decoration: none;
}
.row {
  margin-top: 60px;
}
.health-graphic {
  font-family: interstate, sans-serif;
  font-style: normal;
  font-weight: 100;
}
.large-covid-text {
  font-family: interstate, sans-serif;

  font-weight: 800;
  font-size: 34px;
}
.large-help-text {
  font-family: interstate, sans-serif;

  font-weight: 600;
  font-size: 24px;
}
a:link {
  color: #e21833;
}
a:visited {
  color: #e21833;
}

  a:hover{
   text-decoration: underline;
  }

  .healLine{
    text-decoration: none;
  }

.healLineText{
  font-size:large;
}
</style>