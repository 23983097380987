import Vue from "vue";
import App from "./CovidApp.vue";
import vuetify from "./../../plugins/vuetify";
import store from "./../../store";
import axios from "axios";
import i18n from '@/i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);
axios.defaults.baseURL = "api/";
Vue.config.productionTip = false;

Vue.use({
  install(Vue) {
    Vue.prototype.$axios = axios;
    Vue.prototype.$store = store;
  },
});

new Vue({
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
