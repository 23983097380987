import { render, staticRenderFns } from "./Chatter.vue?vue&type=template&id=47bd088c&scoped=true&"
import script from "./Chatter.vue?vue&type=script&lang=js&"
export * from "./Chatter.vue?vue&type=script&lang=js&"
import style0 from "./Chatter.vue?vue&type=style&index=0&id=47bd088c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47bd088c",
  null
  
)

export default component.exports